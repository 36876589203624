  .form-group {
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 0.9375rem;
      color: $form-group-label-color;
      padding-left: 0.9375rem;
    }

    input,
    input:focus,
    textarea {
      border-color: $input-border-color;
      background-color: $input-background-color;
      color: $input-color;
      border-radius: 1.875rem;
      padding: 0.625rem 1.5625rem;
      font-size: 1rem;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px $input-box-shadow-color inset !important;
      -webkit-text-fill-color: $input-autofill-color !important;
    }

    input::placeholder,
    textarea::placeholder {
      color: $input-placeholder-color;
    }

    .image-preview {
      position: relative;
      width: 150px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
      &-icon {
        position: absolute;
        bottom: 5px;
        right: 0px;
      }
    }
  }
  .form-group-compact {
    @extend .form-group;

    input,
    input:focus,
    textarea {
      padding: 0px;
    }
  }
